import rules from "./rules";
import logo from "./logo.png";
import consumableRules from "./consumableRules";

const config = {
  title: 'Unknown',
  domain: 'wow-unknown.cz',
  language: 'cs',
  modes: ['wotlk'],
  faction: 'horde',
  modules: {
    HOMEPAGE: {
      customComponent: null,
      particles: true,
    },
    GALLERY: {
      enabled: false,
    },
    GUILD_BANK: {
      enabled: false,
      url: 'https://classicguildbank.com/#/guild/readonly/VD5QrwTYU6SvGDmpZ4yfA'
    },
    RULES: {
      enabled: true,
      rules,
      showConsumables: false,
      customTitle: 'Nábor',
    },
    CONSUMABLES: {
      enabled: false,
      consumableRules,
    },
    APPLICATIONS: {
      enabled: true,
      questions: [
        'spec',
        'race',
        'lvl',
        'professions',
        'wb',
        'ironforgeLink',
        'expectations',
        // 'experience',
        'offer',
      ]
    },
    CALENDAR: {
      enabled: true,
    },
    RAID_ROSTER: {
      enabled: true,
    },
    TWITCH: {
      enabled: false,
    }
  },
  firebase: {
    apiKey: "AIzaSyBuo8XvNYab42pbJjWd8B-qqcwK7ZH56eg",
    authDomain: "compromise-wow.firebaseapp.com",
    databaseURL: "https://compromise-wow.firebaseio.com",
    projectId: "compromise-wow",
    storageBucket: "compromise-wow.appspot.com",
    messagingSenderId: "577026926352",
    appId: "1:577026926352:web:4b131c498aec8977f6e795",
    measurementId: "G-EC0MPTEP2B"
  },
  functionsUrl: {
    local: 'http://localhost:5001/compromise-wow/us-central1',
    remote: 'https://us-central1-compromise-wow.cloudfunctions.net',
  },
  theme: {
    template: 'DARK',
    colorPrimary: '#c9a128',
    headerLogo: logo,
  },
  discord: {
    link: 'https://discord.gg/HGvcCeS',
    roles: {
      order: [
        'Guild Master',
        'Officer',
        'Rada Starsich',
        'Class Leader',
        'Core Raider',
        'Raider',
        'Trial',
        'Friend',
        'Novacek',
      ],
      MEMBER: 'Trial',
      OFFICER: 'Class Leader',
    }
  },
  recaptcha: '6LeD2iYaAAAAAApuLc_1wl499pNJXX85imK7-lEg',
  bugsnag: {
    apiKey: 'e015083d72b83f9ec0b419626a5a0f98',
  }
};

export default config;
