const ConsumableRules = {
    COMMON: {
        WARLOCK: {
            Base: {
                watched: {
                    17628: 0.5, // flask

                    17539: 0.19, // gae
                    11390: 0.1, // arcane

                    11474: 0.21, // shadow
                    22730: 0.1, // runn
                },
                substitutes: [
                    [17539, 11390], // arcane
                ]
            },
        },
        MAGE: {
            Base: {
                watched: {
                    17628: 0.5, // flask

                    26276: 0.19, // fire

                    17539: 0.16, // gae
                    11390: 0.1, // arcane

                    24363: 0.1, // mageblood

                    18194: 0.05, // nightfin
                    22730: 0.05, // runn
                },
                substitutes: [
                    [17539, 11390], // arcane
                    [18194, 22730], // food
                ]
            },
        },
        SHAMAN: {
            Healer: {
                watched: {
                    17627: 0.5, // distilled wisdom

                    24363: 0.25, // mageblood
                    10692: {
                        ratio: 0.15,
                        maximum: 1
                    }, // cerebral

                    18194: 0.1, // nightfin
                    25941: 0.06, // sagefish
                },
                substitutes: [
                    [18194, 25941] // food
                ]
            },
            Base: {
                watched: {
                    17628: 0.5, // flask
                    17627: 0.5, // distilled wisdom

                    17539: 0.25, // gae
                    11390: 0.1, // arcane

                    24363: 0.15, // mageblood

                    18194: 0.1, // nightfin
                    22730: 0.1, // runn
                    25941: 0.05, // sagefish
                },
                substitutes: [
                    [17539, 11390], // arcane
                    [18194, 22730, 25941], // food
                    [17628, 17627] // flasks
                ]
            },
        },
        PRIEST: {
            Healer: {
                watched: {
                    17627: 0.5, // distilled wisdom

                    24363: 0.2, // mageblood

                    24382: {
                        ratio: 0.05,
                        maximum: 2
                    }, // spirit of zanza

                    22790: 0.08, // beatdown

                    18194: 0.12, // nightfin
                    25941: 0.1, // sagefish
                    19710: 0.1, // omelete
                    19709: 0.05, // buzzard
                },
                substitutes: [
                    [18194, 19710, 25941, 19709] // food
                ]
            },
            Base: {
                watched: {
                    17628: 0.5, // flask

                    17539: 0.19, // gae
                    11390: 0.1, // arcane

                    11474: 0.21, // shadow
                    22730: 0.1, // runn
                },
                substitutes: [
                    [17539, 11390],
                ]
            },
        },
        DRUID: {
            Healer: {
                watched: {
                    17627: 0.5, // distilled wisdom

                    24363: 0.2, // mageblood

                    22790: 0.08, // beatdown
                    24382: {
                        ratio: 0.05,
                        maximum: 2
                    }, // spirit of zanza

                    18194: 0.12, // nightfin
                    25941: 0.1, // sagefish
                    19710: 0.1, // omelete
                    19709: 0.05, // buzzard
                },
                substitutes: [
                    [18194, 25941, 19710, 19709], // food
                ]
            },
            Tank: {
                watched: {
                    17538: 0.28, // mongoose
                    11334: 0.13, // greater agi
                    11328: 0.07, // agi

                    24382: {
                        ratio: 0.06,
                        maximum: 2
                    }, // spirit of zanza

                    10669: {
                        ratio: 0.08,
                        maximum: 1,
                    }, // assay 25 agi

                    16323: 0.12, // juju power 30 str
                    11405: 0.09, // giants 25 str

                    16329: 0.09, // juju might 40 AP
                    17038: 0.06, // firewater 35 AP

                    11348: 0.09, // superior defense

                    3593: 0.04, // fortitude

                    25661: 0.13, // chimaerok
                    24799: 0.07, // dumplings
                    19710: 0.07, // omelete

                    25804: 0.05, // rumsey black label
                    20875: 0.02, // rumsey rum
                    25722: 0.02, // rumsey rum dark
                    22789: 0.02, // gordok
                },
                substitutes: [
                    [17538, 11334, 11328], // agi
                    [25661, 24799, 19710], // food
                    [25804, 20875, 25722, 22789], // alcohol
                    [16323, 11405], // juju power, giants
                    [16329, 17038], // juju might, firewater
                ]
            },
            Base: {
                watched: {
                    17538: 0.34, // mongoose
                    11334: 0.16, // greater agi
                    11328: 0.09, // agi

                    10669: {
                        ratio: 0.13,
                        maximum: 1
                    }, // assay 25 agi
                    10667: {
                        ratio: 0.06,
                        maximum: 1
                    }, // roids 25 str
                    24382: {
                        ratio: 0.03,
                        maximum: 2
                    }, // spirit of zanza

                    16323: 0.17, // juju power 30 str
                    11405: 0.14, // giants 25 str

                    16329: 0.12, // juju might 40 AP
                    17038: 0.09, // firewater 35 AP

                    18192: 0.12, // grilled squid
                    24799: 0.07, // dumplings 20 str
                    19710: 0.04, // omelete
                },
                substitutes: [
                    [17538, 11334, 11328], // agi
                    [18192, 24799, 19710], // food
                    [16323, 11405], // juju p, giants
                    [16329, 17038], // juju m, firewater
                ]
            },
        },
        WARRIOR: {
            Tank: {
                watched: {
                    17538: 0.28, // mongoose
                    11334: 0.13, // greater agi
                    11328: 0.07, // agi

                    24382: {
                        ratio: 0.06,
                        maximum: 2
                    }, // spirit of zanza

                    10667: {
                        ratio: 0.08,
                        maximum: 1,
                    }, // roids 25 str

                    16323: 0.12, // juju power 30 str
                    11405: 0.09, // giants 25 str

                    16329: 0.09, // juju might 40 AP
                    17038: 0.06, // firewater 35 AP

                    11348: 0.09, // superior defense

                    3593: 0.04, // fortitude

                    25661: 0.13, // chimaerok
                    24799: 0.07, // dumplings
                    19710: 0.07, // omelete

                    25804: 0.05, // rumsey black label
                    20875: 0.02, // rumsey rum
                    25722: 0.02, // rumsey rum dark
                    22789: 0.02, // gordok
                },
                substitutes: [
                    [17538, 11334, 11328], // agi
                    [25661, 24799, 19710], // food
                    [25804, 20875, 25722, 22789], // alcohol
                    [16323, 11405], // juju power, giants
                    [16329, 17038], // juju might, firewater
                ]
            },
            Base: {
                watched: {
                    17538: 0.26, // mongoose
                    11334: 0.12, // greater agi
                    11328: 0.07, // agi

                    16323: 0.16, // juju power 30 str
                    11405: 0.13, // giants 25 str

                    24382: {
                        ratio: 0.06,
                        maximum: 2
                    }, // spirit of zanza
                    10669: {
                        ratio: 0.09,
                        maximum: 1
                    }, // assay 25 agi
                    10667: {
                        ratio: 0.10,
                        maximum: 1
                    }, // roids 25 str

                    16329: 0.14, // juju might 40 AP
                    17038: 0.11, // firewater 35 AP

                    24799: 0.13, // dumplings 20 str
                    18125: 0.06, // sunfruit 10str
                    18192: 0.05, // squid 10 agi
                    25661: 0.04, // chimaerok
                    19710: 0.03, // omelete
                },
                substitutes: [
                    [17538, 11334, 11328], // agi
                    [24799, 18125, 25661, 19710, 18192], // food
                    [16323, 11405], // juju power, giants
                    [16329, 17038], // juju might, firewater
                ]
            },
        },
        ROGUE: {
            Base: {
                watched: {
                    17538: 0.34, // mongoose
                    11334: 0.16, // greater agi
                    11328: 0.09, // agi

                    10669: {
                        ratio: 0.13,
                        maximum: 1
                    }, // assay 25 agi
                    10667: {
                        ratio: 0.06,
                        maximum: 1
                    }, // roids 25 str
                    24382: {
                        ratio: 0.03,
                        maximum: 2
                    }, // spirit of zanza

                    16323: 0.17, // juju power 30 str
                    11405: 0.14, // giants 25 str

                    16329: 0.12, // juju might 40 AP
                    17038: 0.09, // firewater 35 AP

                    18192: 0.12, // grilled squid
                    24799: 0.07, // dumplings 20 str
                    19710: 0.04, // omelete
                },
                substitutes: [
                    [17538, 11334, 11328], // agi
                    [18192, 24799, 19710], // food
                    [16323, 11405], // juju p, giants
                    [16329, 17038], // juju m, firewater
                ]
            },
        },
        HUNTER: {
            Base: {
                watched: {
                    17538: 0.5, // mongoose
                    11334: 0.25, // greater agi
                    11328: 0.12, // agi

                    10669: {
                        ratio: 0.13,
                        maximum: 1
                    }, // assay 25 agi
                    24382: {
                        ratio: 0.03,
                        maximum: 2
                    }, // spirit of zanza

                    16329: 0.17, // juju might 40 AP

                    18192: 0.14, // grilled squid
                    19710: 0.05, // omelete
                },
                substitutes: [
                    [17538, 11334, 11328], // agi
                    [18192, 19710], // food
                ]
            },
        },
    },
};

export default ConsumableRules;
