import React from "react";

const rules = {
    common: [
        {
            title: 'Nábor otevřen',
            content: <React.Fragment />
        },
    ],
    tbc: [],
    classic: [],
};

export default rules;
